import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate,
  useRouteError,
} from '@remix-run/react';
import * as Sentry from '@sentry/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { RiEmotionSadFill } from 'react-icons/ri';
import './styles/fallback.css';

export function Layout({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

const FallbackComponent = (error: any, componentStack: any) => {
  const navigate = useNavigate();
  return (
    <div className="fallbackDiv">
      <RiEmotionSadFill style={{ fontSize: '10vw' }} />
      {/* <img src={logo} width={'100vw'} id="popupLogo" alt="BLUESKEYE AI Logo" /> */}
      {componentStack.toString().includes('QuadrantChart') ? (
        <p>We could not find the video you're looking for.</p>
      ) : (
        <p>You have encountered an error.</p>
      )}
      <p>
        Please inform the project owner or Blueskeye AI for further assistance.
      </p>
      <p>
        Click{` `}
        <span>
          <button
            id="navigateButton"
            onClick={() => {
              navigate(-1);
            }}
          >
            here
          </button>
        </span>
        {` `}to return to the previous page!
      </p>
    </div>
  );
};

export default function App() {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) =>
        FallbackComponent(error, componentStack)
      }
    >
      <Outlet />
    </Sentry.ErrorBoundary>
  );
}
